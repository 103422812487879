// import logo from './logo.svg';
// import './App.css';
// import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About'

function App() {
  return (
    <>
      <Hero />
      <About />
      {/* <Navbar /> */}
    </>
  );
}

export default App;
